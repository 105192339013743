import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiPhoneInput from 'material-ui-phone-number';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './index.css';
import Navbar from '../../components/Navbar';
import countries from '../../components/Countries/country.json';
import { sendSMS, smsKey, } from '../../store/actions/Auth';


class VerifyKYC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                country: '',
                address: '',
                state: '',
                zipCode: '',
                idCard: '',
            }
        };
    };

    handleChange = ({ target }) => {
        const { formData } = this.state;
        formData[target.name] = target.value;
        this.setState({ formData });
    };

    setPhoneNumber = (number) => {
        let { formData } = this.state;
        formData['phone'] = number;
        this.setState({ formData });
    };

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    };

    selectedCountry = (e, value) => {
        let { formData } = this.state;
        formData['country'] = value['label'];
        this.setState({ formData });
    };

    // submitKYC

    render() {
        let { lightMode } = this.props;
        let { formData } = this.state;

        return (
            <div className={`page-wrapper-inner ${lightMode ? 'light' : 'dark'}`}>
                <Navbar {...this.props} />

                <section className="sms-anthtication verify-kyc">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12">
                                <div className="sms-anthtication-form">
                                    <div className="dash-top-title">
                                        <div className='row'>
                                            <div className='col-1 back-btn'>
                                                <Link to='/myPage'><i class="fa fa-arrow-left text-white mt-2" aria-hidden="true"></i></Link>
                                            </div>
                                            <div className='col-11 title d-flex justify-content-center'>
                                                <h2>Verify KYC</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <form className="form-area">
                                        <div className="row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="firstName"
                                                        type='text'
                                                        value={formData['firstName']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="First Name"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="lastName"
                                                        type='text'
                                                        value={formData['lastName']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="Last Name"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="email"
                                                        type='email'
                                                        value={formData['email']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="Email"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <MuiPhoneInput
                                                        fullWidth
                                                        name="phone"
                                                        value={formData['phone']}
                                                        margin="normal"
                                                        autoFormat={false}
                                                        defaultCountry='us'
                                                        id='standard-full-width'
                                                        className='sms-input-field phone-field'
                                                        onChange={(number) => this.setPhoneNumber(number)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <Autocomplete
                                                        fullWidth
                                                        id="country-select-demo"
                                                        className="autocomplete-field"
                                                        options={countries}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, newValue) => this.selectedCountry(event, newValue)}
                                                        onClick
                                                        renderOption={(option) => (
                                                            <React.Fragment>
                                                                <span>{this.countryToFlag(option.code)}</span>
                                                                {'   '} {option.label} ({option.code})
                                                            </React.Fragment>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                id='standard-full-width'
                                                                className='sms-input-field country-field'
                                                                placeholder="Country"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                }}
                                                            />

                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="address"
                                                        type='text'
                                                        value={formData['address']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="Address"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="state"
                                                        type='text'
                                                        value={formData['state']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="State"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-item">
                                                    <TextField
                                                        fullWidth
                                                        name="zipCode"
                                                        type='text'
                                                        value={formData['zipCode']}
                                                        id='standard-full-width'
                                                        className='sms-input-field'
                                                        placeholder="Zip Code"
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mt-3 text-center col-lg-12 col-md-12 col-sm-12">
                                                <button type="button" className="theme-btn btn-style-four submit-auth-btn" onClick={this.submitKYC}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

const mapDispatchToProps = { sendSMS, smsKey };
const mapStateToProps = ({ Auth, Trading }) => {
    let { lightMode } = Auth;
    let { } = Trading;
    return { lightMode };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyKYC);